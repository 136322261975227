import sha1 from "sha1";
import request from "@/utils/fetch";

const APPID = "wx7a2a1b717bdec6cd";

// 获取微信ticket
export const getWXTicket = (reloadCache = 0) => {
  return request(
    `/c-tpf-consumer/wechat/getJsTicket?reloadCache=${reloadCache}`
  );
};

export function initWx() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "//res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    script.id = "WXSDK";
    script.onload = async () => {
      const data = await getWXTicket();
      const { origin, pathname, search } = window.location;
      const url = origin + pathname + search;
      const appId = APPID;
      const timestamp = Date.now();
      const nonceStr = "taocheche";
      const signature = sha1(
        `jsapi_ticket=${data?.ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${url}`
      );
      window.wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
        appId, // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr, // 必填，生成签名的随机串
        signature, // 必填，签名
        jsApiList: ["updateAppMessageShareData"], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-app"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      window.wx.ready(() => {
        resolve(window.wx);
      });

      window.wx.error((err) => {
        console.info("微信sdk错误", err);
      });
    };
    document.body.append(script);
  });
}

export const getOpenId = async () => {
  const searchParams = getSearchParams();
  const localOpenId = window.localStorage.getItem("wx_openId");
  // 本地没有openId 且 url中有code 调用接口获取openId
  if (searchParams.code) {
    if (!localOpenId) {
      const { openId } = await request.get(
        "/c-tpf-consumer/wechat/getPublicAccountUserInfo",
        {
          params: {
            code: searchParams.code,
          },
        }
      );
      window.localStorage.setItem("wx_openId", openId);
      return openId;
    }
    return localOpenId;
  }
  // 没有code 跳转微信授权页
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${encodeURIComponent(
    window.location.href
  )}&response_type=code&scope=snsapi_base#wechat_redirect`;
};
